import Player, { VimeoPromise } from '@vimeo/player';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';
import Meta from '../components/layout/Meta';
import UniversityLayout from '../components/layout/UniversityLayout';
import UntitledButton from '../components/ui/UntitledButton';
import UniversityCourseFull from '../components/university/UniversityCourseFull';
import { UniversityLessonsYaml } from '../graphql-types';
import logo from '../images/logo-white.svg';
import caret from '../images/university/caretRight.svg';
import helpIcon from '../images/university/help.svg';
import playLargeIcon from '../images/university/playLarge.svg';
import templateIcon from '../images/university/template.svg';
import websiteIcon from '../images/university/website.svg';
import secondsToTimestamp from '../utils/secondsToTimestamp';
import { toUniversityLesson } from '../utils/toUniversityType';
import styles from './UniversityLessonTemplate.module.scss';

const RESOURCE_KIND_META: {
  [key: string]: {
    icon: string;
    displayName: string;
  };
} = {
  help: {
    icon: helpIcon,
    displayName: 'Help Doc',
  },
  template: {
    icon: templateIcon,
    displayName: 'Template',
  },
  website: {
    icon: websiteIcon,
    displayName: 'Website',
  },
};

interface UniversityLessonTemplateProps {
  data: {
    lesson: UniversityLessonsYaml;
  };
}

interface Subject {
  startTime: number;
  title: string;
}

// @types/vimeo__player is outdated
interface PlayerWithChapters extends Player {
  getChapters(): VimeoPromise<Subject[], Error>;
}

const UniversityLessonTemplate: React.SFC<UniversityLessonTemplateProps> = ({
  data,
}) => {
  const lesson = toUniversityLesson(data.lesson);
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const playerRef = useRef<PlayerWithChapters | null>(null);
  const [subjects, setSubjects] = useState<Subject[]>([]);
  const [activeSubjectStartTime, setActiveSubjectStartTime] =
    useState<number>(0);
  const [hasPlayed, setHasPlayed] = useState<boolean>(false);

  useEffect(() => {
    if (iframeRef.current) {
      playerRef.current = new Player(iframeRef.current) as PlayerWithChapters;
      playerRef.current.on('play', () => setHasPlayed(true));
      playerRef.current.getChapters().then(setSubjects);
      playerRef.current.on('chapterchange', (chapter: Subject) => {
        setActiveSubjectStartTime(chapter.startTime);
      });
    }
  }, []);

  const help = (
    <div className={styles.help}>
      <div>
        <div className={styles.helpHeader}>Still need help?</div>
        <div className={styles.helpText}>
          Explore more resources in Slab&apos;s Help Center.
        </div>
      </div>
      <div className={styles.helpButton}>
        <UntitledButton
          variant="primary"
          to="https://help.slab.com"
          target="_blank"
          block
          large
        >
          Visit Help Center
        </UntitledButton>
      </div>
    </div>
  );

  return (
    <UniversityLayout>
      <Meta title={`${lesson.name} - Slab University`} />
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.courseBreadcrumb}>
            {lesson.course.name}
            <img
              className={styles.caret}
              src={caret}
              alt=""
              role="presentation"
            />
          </div>
          <h1 className={styles.name}>{lesson.name}</h1>
          <h2 className={styles.description}>{lesson.description}</h2>
        </div>
        <div className={styles.video}>
          <div
            className={styles.iframeContainer}
            style={{ paddingBottom: `${100 / lesson.aspectRatio}%` }}
          >
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              ref={iframeRef}
              src={`https://player.vimeo.com/video/${lesson.vimeoId}`}
              frameBorder="0"
              allowFullScreen
              allow="autoplay"
              sandbox="allow-scripts allow-popups allow-same-origin"
            />
            {!hasPlayed && (
              <button
                type="button"
                className={styles.placeholder}
                onClick={() => {
                  if (playerRef.current) {
                    playerRef.current.play();
                    setHasPlayed(true);
                  }
                }}
              >
                <img
                  className={styles.placeholderBackground}
                  src={lesson.course.background.publicURL}
                  alt=""
                  role="presentation"
                />
                <div className={styles.placeholderContent}>
                  <div>
                    <div className={styles.placeholderCourse}>
                      {lesson.course.name}
                    </div>
                    <div className={styles.placeholderLesson}>
                      {lesson.name}
                    </div>
                  </div>
                  <img
                    src={playLargeIcon}
                    className={styles.placeholderPlay}
                    alt="Play button"
                  />
                  <img
                    src={logo}
                    className={styles.placeholderLogo}
                    alt="Slab"
                  />
                </div>
              </button>
            )}
          </div>
        </div>
        <div className={styles.course}>
          <div className={styles.nextUp}>Next up</div>
          <UniversityCourseFull course={lesson.course} activeNum={lesson.num} />
        </div>
        <div className={styles.secondary}>
          {subjects.length > 0 && (
            <div className={styles.subjects}>
              <div className={styles.subjectsTitle}>Subjects</div>
              {subjects.map((subject) => (
                <UntitledButton
                  key={subject.startTime}
                  onClick={() => {
                    if (playerRef.current) {
                      playerRef.current.setCurrentTime(
                        subject.startTime + 0.01,
                      );
                      if (!hasPlayed) {
                        playerRef.current.play();
                        setHasPlayed(true);
                      }
                    }
                  }}
                  block
                >
                  <div
                    className={classNames(styles.subject, {
                      [styles.active]:
                        subject.startTime === activeSubjectStartTime,
                    })}
                  >
                    <div className={styles.subjectTitle}>{subject.title}</div>
                    <div className={styles.subjectStart}>
                      {secondsToTimestamp(subject.startTime, {
                        stripLeadingZero: true,
                      })}
                    </div>
                  </div>
                </UntitledButton>
              ))}
            </div>
          )}
          {lesson.resources.length > 0 && (
            <div className={styles.resources}>
              <div className={styles.resourcesTitle}>Resources</div>
              {lesson.resources.map((resource) => (
                <a
                  key={resource.url}
                  href={resource.url}
                  target="_blank"
                  className={classNames(
                    styles.resource,
                    styles[`${resource.kind}Resource`],
                  )}
                  rel="noreferrer"
                >
                  <div className={styles.resourceIcon}>
                    <img
                      src={RESOURCE_KIND_META[resource.kind].icon}
                      alt=""
                      role="presentation"
                    />
                  </div>
                  <div className={styles.resourceInfo}>
                    <div className={styles.resourceKind}>
                      {RESOURCE_KIND_META[resource.kind].displayName}
                    </div>
                    <div className={styles.resourceTitle}>{resource.name}</div>
                    {resource.kind === 'website' && (
                      <div className={styles.resourceHostname}>
                        {new URL(resource.url).hostname}
                      </div>
                    )}
                  </div>
                </a>
              ))}
            </div>
          )}
          <div className={styles.helpDesktop}>{help}</div>
        </div>
        <div className={styles.helpMobile}>{help}</div>
      </div>
    </UniversityLayout>
  );
};

export const query = graphql`
  query UniversityLessonPageQuery(
    $slug: String!
    $courseSlug: String!
    $roleSlug: String!
  ) {
    lesson: universityLessonsYaml(
      fields: {
        slug: { eq: $slug }
        courseSlug: { eq: $courseSlug }
        roleSlug: { eq: $roleSlug }
      }
    ) {
      num
      vimeoId
      resources {
        name
        kind
        url
      }
      course {
        name
        description
        invertIconText
        num
        lessons {
          num
          fields {
            name
            duration
            roleSlug
            slug
          }
        }
        fields {
          icon {
            publicURL
          }
          background {
            publicURL
          }
        }
      }
      fields {
        name
        description
        duration
        aspectRatio
      }
    }
  }
`;

export default UniversityLessonTemplate;
